import { getEnvOrThrow, useMonkAppState } from '@monkvision/common';
import { useMonkApi } from '@monkvision/network';
import {
  CreatePricingTaskOptions,
  SteeringWheelPosition,
  TaskName,
  VehicleType,
} from '@monkvision/types';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { TeslaCountry, TeslaInspectionStatus, TeslaModel } from '../useTeslaInspectionList/types';
import { CreateTeslaInspectionParams, TeslaAdditionalData } from './types';
import { AppRegion, useAppRegion } from '../../contexts';
import { getPricingConfig, getPricingOutputFormat } from './pricing';

const CAPTURE_APP_URL = getEnvOrThrow('REACT_APP_CAPTURE_APP_URL');

function getVehicleType(model: TeslaModel): VehicleType {
  switch (model) {
    case TeslaModel.MODEL_3:
      return VehicleType.SEDAN;
    case TeslaModel.MODEL_S:
      return VehicleType.SEDAN;
    case TeslaModel.MODEL_X:
      return VehicleType.CUV;
    case TeslaModel.MODEL_Y:
      return VehicleType.CUV;
    default:
      return VehicleType.CUV;
  }
}

export function useCreateTeslaInspectionLink() {
  const { region } = useAppRegion();
  const { config, authToken } = useMonkAppState();
  const { createInspection } = useMonkApi({
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  });

  const createInspectionLink = useCallback(
    async (params: CreateTeslaInspectionParams) => {
      if (!config.allowCreateInspection) {
        throw new Error(
          'Unable to create a new inspection because config.allowCreateInspection is set to false',
        );
      }
      if (!authToken) {
        throw new Error('Unable to create a new inspection because auth token is not defined');
      }
      const steeringWheel = [TeslaCountry.UK].includes(params.country)
        ? SteeringWheelPosition.RIGHT
        : SteeringWheelPosition.LEFT;
      const additionalData: TeslaAdditionalData = {
        country: params.country,
        lease_end_date:
          region === AppRegion.US
            ? dayjs('2025-01-01').toISOString()
            : params.leaseMaturityDate.toISOString(),
        customer_status: TeslaInspectionStatus.INSPECTION_LINK_SEND,
        last_update_date: new Date().toISOString(),
        steering_wheel_position: steeringWheel,
      };
      const nonPricingTasks = config.createInspectionOptions.tasks.filter((task) =>
        typeof task === 'object' ? task.name !== TaskName.PRICING : task !== TaskName.PRICING,
      );
      const pricingTaskOptions = config.createInspectionOptions.tasks.find(
        (task) => typeof task === 'object' && task.name === TaskName.PRICING,
      ) as CreatePricingTaskOptions | undefined;
      if (!pricingTaskOptions) {
        throw new Error(
          'Unable to create inspection because pricing tasks options are not defined in the current live config',
        );
      }
      const { id } = await createInspection({
        ...config.createInspectionOptions,
        tasks: [
          ...nonPricingTasks,
          {
            ...pricingTaskOptions,
            outputFormat: getPricingOutputFormat(params),
            config: getPricingConfig(params),
          },
        ],
        vehicle: {
          brand: 'Tesla',
          vin: params.vin,
          plate: params.licencePlate,
          model: params.model,
          mileageUnit: params.mileageUnit,
          mileageValue: params.odometer,
        },
        additionalData,
      });
      return `${CAPTURE_APP_URL}/?i=${id}&v=${getVehicleType(params.model)}&s=${steeringWheel}`;
    },
    [region, config, createInspection, authToken],
  );

  return { createInspectionLink };
}
